<template>
  <div
    component-name="wrapper-roomvo"
    class="roomvo-container"
    :data-product-type-str="str"
  >
    <div
      class="roomvo-stimr"
      :data-sku="sku"
      style="visibility: hidden"
      @click="handleDataLayer"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
const { pushDataLayer } = useDataLayer();
const props = defineProps({
  str: {
    type: String,
    required: false,
    validator: function (value) {
      return ["floor", "wall"].indexOf(value) !== -1;
    },
    defult: "floor",
  },
  sku: {
    type: String,
    required: true,
  },
});

function handleDataLayer() {
  pushDataLayer({
    event: "click_configurator_roomvo_on_product",
    type: props.str,
    visualizer_code: props.sku,
  });
}
</script>
